@import "~bootstrap/scss/bootstrap";

.circle-black {
  background: url("../../images/black-circle.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: absolute;
  // transform: translate(5vw, -50vh);
  transform: translate(0,-47vh);

}


.guide {

  &__container{
    color: #fff;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    padding-top: 25vw;
    background: url("../../images/moon-830444_1920.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    min-height: 100vh;

    .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        box-align: center;  
        align-items: center;

        .cell {
          flex: 1 1 auto; 
          margin: 10px;
          text-align: center;
          
          img {max-width:100%;}
      }
    }
  
    .heading {
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 28px;
      color: navajowhite;
      margin-bottom: 30px;
      font-family: "Comfortaa";
      letter-spacing: 2px;
    }

    &__intro {
      padding-bottom: 20px;
      margin-top: 5vh;

      .content {
        color: darkgrey;
      }
    }

    &__howto {
      padding-bottom: 20px;

      ol {
        //max-width: 350px;
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
        color: darkgrey;
      }
      ol li {
        margin: 0 0 1rem 0;
        counter-increment: my-awesome-counter;
        position: relative;
      }
      ol li::before {
        content: "0" counter(my-awesome-counter);
        //color: #fcd000;
        font-size: 14px;
        //font-weight: bold;
        font-family: Roboto;
        position: absolute;
        --size: 41px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        border: 1px solid $white;
        //transform: rotate(-10deg);
        //background: black;
        border-radius: 50%;
        text-align: center;
        //box-shadow: 1px 1px 0 #999;
      }
    }

    &__roles {
      overflow-x: hidden;
      padding-bottom: 55px;
      &__carousel {
        display: flex;
        overflow-x: auto;
        flex: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        padding: 10px;
        height: 180px;

        &::-webkit-scrollbar {
          width: 10px;
          height: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(255,255,255,.2);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        .role-card {
          border: 3px solid rgba(158, 98, 13, .4);
          .role-name {
            font-family: Comfortaa;
            color: rgba(158, 98, 13, .8);
            line-height: 22px;
          }
        }
      }
    }

    .content {
      font-style: normal;
      font-weight: normal;
      font-size: 1em;
      line-height: 1.5em;
    }
  }


}

@media screen and (max-width: 700px) {
  .guide  {
    background-image: url("../../images/moon-830444_640.jpg");
    background-attachment:scroll;
    background-position:center;
    background-size:cover;
    width: 100%;
    // min-height: 100vh;
    height: 60vh;
    &__container {
      background-image: none;
      background: rgba(255,255,255,.08);
      padding-top: 7vh;
      border-radius: 8px;
    }
  }
}