@import "~bootstrap/scss/bootstrap";

.confirm-wrapper {
  // background: url("../../images/default-background.jpg");
  background: url("../../images/Black-Wolf-Wallpaper.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  font-family: "Comfortaa";

  .clouds2{
    background:transparent url("../../images/clouds.png") repeat top center;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    transform: translate(0, 100px);
  
    animation:move-clouds3-back 300s linear infinite;
  }

  @keyframes move-clouds3-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
  }
}

.host-confirm {
  text-align: center;
  color: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  h1 {
    color: $white;
    font-family: "Comfortaa";
    font-weight: 100;
    text-align: center;
    font-size: 5em !important;

    #we {
      text-shadow: 0 0 5px white, 0 0 8px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #re {
      text-shadow: 0 0 5px antiquewhite, 0 0 8px antiquewhite, 2px 2px 2px rgba(206,89,55,0);
    }
    #wolf {
      text-shadow: 0 8px 20px white, 0 8px 50px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #remote {
      font-size: 20px;
      color: antiquewhite;
    }
    
  }

  @media screen and (max-width: 780px) {
    h1 {
      font-size: 3em !important;
    }
  }


  h3 {
    text-transform: uppercase;
    margin-top: 25px;
    font-size: 22px;
    word-spacing: 2px;
  }

  .input-group {
    margin-top: 20px;
  }

  @media screen and (max-width: 780px) {

    h3 {
      font-size: 15px;
    }
    .input-group {
      width: 400px;
    }
  }

  button {
    color: antiquewhite;
    background:black;
    border: 1px solid antiquewhite;
    font-family: "Comfortaa";
   
    &:hover {
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid antiquewhite !important;
    }
    &:focus {
      color: antiquewhite;
      background: black;
      border: 1px solid antiquewhite;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.4) !important;
      border: 1px solid antiquewhite !important;
    }
  }


  
}
