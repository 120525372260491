@import "~bootstrap/scss/bootstrap";

.start {
  color: $white;

  .navbar {
    top: 15px;

    .home {
      position: absolute;
      font-size: 13px;
      color: rgba(255, 222, 173, .8);
      font-family: "Comfortaa";
      padding: 7px;
      padding-top: 2px;
      padding-bottom: 1px;
      border-radius: 3px;
      text-decoration: none;

      &:active {
        transition: all .8s ease;
        color: rgba(255, 222, 173, 1);
        border: 1px solid rgba(255, 222, 173, 1);

      }
    }
  }

  &__title {
    color: $white;
    font-family: "Comfortaa";
    font-weight: 100;
    text-align: center;
    font-size: 5em;
    margin-top: 40px;

    a:hover{
      text-decoration: none;
    }

    #we {
      text-shadow: 0 0 5px white, 0 0 8px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #re {
      text-shadow: 0 0 5px antiquewhite, 0 0 8px antiquewhite, 2px 2px 2px rgba(206,89,55,0);
    }
    #wolf {
      text-shadow: 0 8px 20px white, 0 8px 50px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #remote {
      font-size: 20px;
      color: antiquewhite;
    }

  }

  @media screen and (max-width: 780px) {
    &__title {
      font-size: 3em;
    }
  }

  .room-size-selection {
    text-align: center;
    padding-bottom: 25px;
    font-family: "Comfortaa";

    .dropdown {

      display: inline-block;

      .dropdown-toggle {
        color: antiquewhite;
        margin-top: 20px;
        border: 1px solid antiquewhite !important;
        text-transform: uppercase;
      }
  
      .dropdown-menu {
        background: black;
        border: 1px solid antiquewhite;
        color: antiquewhite !important;
  
        .dropdown-item {
          background:black;
          color: antiquewhite;
          &:hover {
            background:	rgba(250,235,215, .4)
          }
        }
      }
  
      .room-size {
        padding-left: 25px;
      }
    }

    .total-count {
      display: inline-block;
      font-size: 16px;
      color: antiquewhite;
      
      span {
        color: gold;
        letter-spacing: 2px;
        background: radial-gradient(dimgrey, transparent);
        padding: 4px 8px 4px;
        border-radius: 5px;
      }
    }
  }

  .side-labels {
    font-family: "Comfortaa";
    text-align: center;
    margin-bottom: 40px;
    font-size: 15px;

    p {
      margin-bottom: 6px;
    }
  }

  .container {
    text-align: center;
    margin-top: 10px;

    img {
      border-radius: 10px;
      max-width: 150px;
      border: 2px solid grey;
      filter: drop-shadow(0px 0px 8px);
    }

    .role {
      border-radius: 10px;
      background: $gray-500;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;
      padding: 15px;
      font-size: 23px;
      font-family: "Comfortaa";
      color: antiquewhite;
      font-style: none;

      background: url("../../images/pngtree-cartoon-hand-drawn-moonlight-wolf-howling-hunting-background-material-image_158632.jpg")no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      // box-shadow: 0px 0px 10px antiquewhite, 0px 0px 12px antiquewhite, 0px 0px 10px antiquewhite;
      box-shadow: 0px 0px 3px antiquewhite, 0px 0px 5px antiquewhite, 0px 0px 5px antiquewhite;
      max-width: 250px;

    }
    .role-add {
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.08);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;
      padding: 15px;
      font-size: 23px;
      font-family: "Comfortaa";
      color: antiquewhite;
      font-style: none;
      height: 301px;
      max-width: 250px;
      border: 1px dashed white;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer;
      }

      p {
        transform: translate(0, 90px);
        font-size: 22px;
      }
    }

  }

  .confirm {
    text-align: center;
  }

  .btn {
    margin: 20px 20px;
    height: 45px;
    color: antiquewhite !important;
    background:black !important;
    border: 1px solid antiquewhite !important;
    font-family: "Comfortaa" !important;
    &:hover {
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid antiquewhite !important;
    }
    &:focus {
      color: antiquewhite !important;
      background: black !important;
      border: 1px solid antiquewhite !important;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.4) !important;
      border: 1px solid antiquewhite !important;
    }

  }

  .clouds{
    background:transparent url("../../images/clouds-color.png") repeat top center;
    position: fixed;
    z-index: -3;
    width: 100%;
    height: 100vh;
    transform: translate(0, -120px);

    animation:move-clouds-back 200s linear infinite;
  }

  .clouds2{
    background:transparent url("../../images/clouds.png") repeat top center;
    position: fixed;
    z-index: -4;
    width: 100%;
    height: 100vh;
    transform: translate(0, 200px);

    animation:move-clouds2-back 300s linear infinite;
  }

  @keyframes move-clouds-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
  }

  @keyframes move-clouds2-back {
    from {background-position:10000px 0;}
    to {background-position:0 0;}
  }

}


// Custom Role Modal
.modal-content {

  font-family: "Comfortaa";
  background: url("../../images/blurred-hoodies.jpg") no-repeat center center;
  color: antiquewhite !important;
  box-shadow: 0px 0px 3px antiquewhite, 0px 0px 5px antiquewhite, 0px 0px 5px antiquewhite;
  border-radius: 10px !important;

  .modal-header {
    border: none !important;

    button:focus {outline:0;}

    span {
      color: antiquewhite;
    }
  }
  
  .modal-body {
    text-align: center;
    padding-bottom: 0;
    .custom-row {
      margin-bottom: 2rem;
  
      p {
        font-size: 18px;
        margin-bottom: 0.3rem;
      }
      input {
        padding: 0 8px 0;
        height: 28px;
      }
      select {
        height: 28px;
        padding: 0 5px 0;
      }
    }
  }
  
  .modal-footer {
    border: none !important;

    button {
      // margin: 20px 20px;
      color: antiquewhite;
      background:black;
      border: 1px solid antiquewhite;
      font-family: "Comfortaa";

      &:hover {
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.2) !important;
        border: 1px solid antiquewhite !important;
      }
      &:focus {
        color: antiquewhite;
        background: black;
        border: 1px solid antiquewhite;
      }
      &:active {
        outline: none !important;
        box-shadow: none !important;
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.4) !important;
        border: 1px solid antiquewhite !important;
      }
    }
  }
}


