
.poll {
  .player {
    cursor: pointer;
    border: 3px solid grey;
    &.dead {
      cursor: not-allowed;
    }
    &.selected {
      border: 5px solid black;
    }
    &.disable-cursor{
      cursor: not-allowed;
    }
  }
}

.float-right {
  color: antiquewhite;
  background: black !important;
  border: 1px solid antiquewhite;
  font-family: "Comfortaa";

  &:focus {
    color: antiquewhite;
    background: black !important;
    border: 1px solid antiquewhite;
  }

}

.Toastify__toast {
  font-family: "Comfortaa";
  width: 500px;
  padding-left: 20px;
  border-radius: 5px;
  color: antiquewhite;
}
