.abstain-card {
  .container {
    .col {
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
      color: #867E6E;
      font-size: 1.3em;

      i {
        color: rgba(255, 0, 0, 0.6);
      }
    }
  }
}




