.terms-container {

  margin: 30px;

  .navbar {
    top: 15px;
    margin-bottom: 30px;
    display: block;
    text-align: center;

    .home {
      // position: absolute;
      font-size: 20px;
      color: rgba(255, 222, 173, .8);
      font-family: "Comfortaa";
      padding: 7px;
      padding-top: 2px;
      padding-bottom: 1px;
      border-radius: 3px;
      text-decoration: none;


      &:active {
        transition: all .8s ease;
        color: rgba(255, 222, 173, 1);
        border: 1px solid rgba(255, 222, 173, 1);

      }
    }
  }

  h2, h3 {
    color: papayawhip;
  }
  p, li {
    color: darkgrey;
  }
} 