// @import url('https://fonts.googleapis.com/css?family=Allura|Josefin+Sans');
@import "~bootstrap/scss/bootstrap";

.team_wrapper{
  font-family: 'Comfortaa';
  height: 100vh;

  .navbar {
    top: 15px;

    .home {
      position: absolute;
      font-size: 15px;
      color: rgba(255, 222, 173, .8);
      font-family: "Comfortaa";
      padding: 7px;
      padding-top: 2px;
      padding-bottom: 1px;
      border-radius: 3px;
      text-decoration: none;

      &:active {
        transition: all .8s ease;
        color: rgba(255, 222, 173, 1);
        border: 1px solid rgba(255, 222, 173, 1);

      }
    }
  }

    @media screen and (max-width: 780px) {
      h1 {
        font-size: 3em;
      }
    }

  .team_title {
    margin-top: 50px;
    text-align: center;
    font-size: 1.5em !important;
    color: #ccc;
    letter-spacing: 3px;
    z-index: 3;
  }

  .team_email_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .team_email {
      display: inline-block;
      margin-top: 70px;
      text-align: center;
      font-size: 1em !important;
      color: rgba(255, 222, 173, .8);
      letter-spacing: 3px;
      z-index: 3;
      unicode-bidi:bidi-override;
      direction:rtl;

      &:hover {
        color: rgba(255, 222, 173, 1);
        cursor: pointer;
      }
    }
  }

  .team{
    display: flex;
    justify-content: center;
    width: auto;
    text-align: center;
    flex-wrap: wrap;
    margin-top: 20vh;


    .team_member{
      margin: 18px;
      margin-bottom: 50px;
      width: 300px;
      padding: 20px;
      line-height: 20px;
      color: #8e8b8b;
      position: relative;
      border-radius: 20px;
      z-index: 1000;
      background:none;
      // border: 1px solid grey;
      // background:rgba(255,255,255,0.1);

      .team_img {
        background: navajowhite !important;
        img {
          padding: 2px !important;
        }
      }

      h3{
        color: navajowhite;
        font-size: 40px;
        margin-top: 60px;
        font-family: "Caveat";
      }

      .role{
        color: #ccc;
        margin: 12px 0;
        font-size: 15px;
        // text-transform: uppercase;
        letter-spacing: 2px;
      }

      .member_description {
        margin-top: 30px;
        font-size: 13px;
        // font-family: "Caveat";
      }

      .team_img{
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;

        img{
          width: 120px;
          height: 120px;
          padding: 5px;
        }
      }
    }
  }
}



@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

.stars, .twinkling {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.stars {
background:#000 url(../../images/stars.png) repeat top center;
z-index:-2;
}

.twinkling{
background:transparent url(../../images/twinkling.png) repeat top center;
z-index:-1;

-moz-animation:move-twink-back 300s linear infinite;
-ms-animation:move-twink-back 300s linear infinite;
-o-animation:move-twink-back 300s linear infinite;
-webkit-animation:move-twink-back 300s linear infinite;
animation:move-twink-back 300s linear infinite;
}

