@import "~bootstrap/scss/bootstrap";

.preplayer-wrapper {
  // background: url("../../images/default-background.jpg");
  background: url("../../images/Black-Wolf-Wallpaper.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 110vh;
  color: $white;
  font-family: 'Comfortaa';


  .clouds2{
    background:transparent url("../../images/clouds.png") repeat top center;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    transform: translate(0, -30px);

    animation:move-clouds3-back 300s linear infinite;
  }

  @keyframes move-clouds3-back {
    from {background-position:0 0;}
    to {background-position:10000px 0;}
  }
  h1 {
    color: $white;
    font-family: "Comfortaa";
    font-weight: 100;
    text-align: center;
    font-size: 4em !important;
    padding-top: 30px;

    #we {
      text-shadow: 0 0 5px white, 0 0 8px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #re {
      text-shadow: 0 0 5px antiquewhite, 0 0 8px antiquewhite, 2px 2px 2px rgba(206,89,55,0);
    }
    #wolf {
      text-shadow: 0 8px 20px white, 0 8px 50px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #remote {
      font-size: 20px;
      color: antiquewhite;
    }

  }

  @media screen and (max-width: 780px) {
    h1 {
      font-size: 3em !important;
    }
  }


  .preplayer {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      box-shadow: 0px 0px 10px antiquewhite, 0px 0px 3px antiquewhite, 0px 0px 3px antiquewhite;
      border-radius: 10px;
      text-align: center;
      padding: 20px;
      width: 400px;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
      color: antiquewhite;
      background-color: rgba(0,0,0, 0.5);
      z-index: 10;

    }

    &__room {
      font-size: 20px;
      text-align: left;

      span {
        font-size: 16px;
      }
    }

    &__nameInput {

      label {
        font-size: 28px;
        margin-top: 30px;
      }
      input {
        display: inline-block;
        text-align: center;
        width: 72%;
        height: 33px;
        font-size: 20px;
        text-transform: uppercase;
        border-radius: 10px;
        background: rgba(255,255,255,0.2);
        border: 1px solid antiquewhite;
        color: white;
      }
    }

    &__image-container {

      .mt-5 {
        margin-top: 10px !important;
        width: 150px;
        height: 150px;
        transition: all 0.5s ease;
        @keyframes blink {
          0% { box-shadow: 0 0 20px antiquewhite; }
          50% { box-shadow: none; }
          100% { box-shadow: 0 0 20px antiquewhite; }
        }
        animation: blink 2.0s linear infinite;
        // box-shadow: 1px 1px 8px white, 0 0 20px white, 0 0px 10px darkgoldenrod;

        &:hover {
          box-shadow: 1px 1px 8px white, 0 0 20px white, 0 0px 10px darkgoldenrod;
        }
      }
      .size-warning {
        color: palevioletred;
        font-size: 10px;
        margin-top: 5px;
      }
      .size-success {
        color: springgreen;
        font-size: 10px;
        margin-top: 5px;
      }

    }

    &__selfie {
      margin-top: 10px;

      button {
        background: black;
        color: antiquewhite;
        border: 1px solid antiquewhite;

        &:hover {
          color: antiquewhite !important;
          background: rgba(255, 255, 255, 0.2) !important;
          border: 1px solid antiquewhite !important;
        }
        &:focus {
          color: antiquewhite;
          background: black;
          border: 1px solid antiquewhite;
        }
        &:active {
          outline: none !important;
          box-shadow: none !important;
          color: antiquewhite !important;
          background: rgba(255, 255, 255, 0.4) !important;
          border: 1px solid antiquewhite !important;
        }
      }
    }

    &__join {
      margin-top: 22px;
      margin-bottom: 20px;

      .error {
        padding-top: 10px;
        color: $red;
      }


      button {
        background: black;
        color: antiquewhite;
        border: 1px solid antiquewhite;

        &:hover {
          color: antiquewhite !important;
          background: rgba(255, 255, 255, 0.2) !important;
          border: 1px solid antiquewhite !important;
        }
        &:focus {
          color: antiquewhite;
          background: black;
          border: 1px solid antiquewhite;
        }
        &:active {
          outline: none !important;
          box-shadow: none !important;
          color: antiquewhite !important;
          background: rgba(255, 255, 255, 0.4) !important;
          border: 1px solid antiquewhite !important;
        }
      }
    }


  }
  .hidden {
    display:none;
  }

  .react-html5-camera-photo {
    height: 70px;
  }
  .react-html5-camera-photo video{
    width: 80px;
    height: 80px;
  }
  .react-html5-camera-photo img{
    display: none !important;
  }

  #outer-circle {
    display: none;
  }

  #display-error {
    width: 200px  !important;
    h1 {
      font-size: 10px  !important;
      margin-top: 20px  !important;
      padding: 8px  !important;
      color: black  !important;
    }
  }

}
