@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  background-color: $black !important;
  font-family: 'Comfortaa';

}

@font-face {
  font-family: 'Comfortaa';
  src: url(./fonts/Comfortaa-Regular.ttf) format("woff");
}

@font-face {
  font-family: 'Caveat';
  src: url(./fonts/Caveat-Regular.ttf) format("woff");
}

@font-face {
  font-family: 'arial';
  src: url(./fonts/ArialCE.ttf) format("woff");
}

@font-face {
  font-family: 'roboto';
  src: url(./fonts/Roboto-Bold.ttf) format("woff");
}

// Debug Helpers
.debug-mode {
   display: none;
}

.debug {
  .debug-mode {
    display: initial;
  }
}

.player-image {
  object-fit: cover;
}