@import "~bootstrap/scss/bootstrap";

.landing {
  background: url("../../images/Black-Wolf-Wallpaper.jpg");
  // background: url("../../images/default-background.jpg");
  // background: url("../../images/blurred-hoodies.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  font-family: "Comfortaa";
  z-index: 1000;
  // background-attachment: fixed;

  .navbar {
    top: 15px;
    z-index: 3000;
    font-size: 15px;
  }

  .about {
    position: absolute;
    // font-size: 13px;
    color: rgba(255, 222, 173, .8);
    font-family: "Comfortaa";
    padding: 7px;
    padding-top: 2px;
    padding-bottom: 1px;
    border-radius: 3px;
    text-decoration: none;

    &:active {
      transition: all .8s ease;
      color: rgba(255, 222, 173, 1);
      border: 1px solid rgba(255, 222, 173, 1);

    }
  }
  .demo {
    position: absolute;
    left: 90px;
    color: rgba(255, 222, 173, .8);
    font-family: "Comfortaa";
    padding: 7px;
    padding-top: 2px;
    padding-bottom: 1px;
    border-radius: 3px;
    text-decoration: none;

    &:active {
      transition: all .8s ease;
      color: rgba(255, 222, 173, 1);
      border: 1px solid rgba(255, 222, 173, 1);

    }
  }
  .team {
    position: absolute;
    left: 175px;
    // font-size: 13px;
    color: rgba(255, 222, 173, .8);
    font-family: "Comfortaa";
    padding: 7px;
    padding-top: 2px;
    padding-bottom: 1px;
    border-radius: 3px;
    text-decoration: none;

    &:active {
      transition: all .8s ease;
      color: rgba(255, 222, 173, 1);
      border: 1px solid rgba(255, 222, 173, 1);

    }
  }

  .contact {
    position: absolute;
    left: 240px;
    // font-size: 13px;
    color: rgba(255, 222, 173, .8);
    font-family: "Comfortaa";
    padding: 7px;
    padding-top: 2px;
    padding-bottom: 1px;
    border-radius: 3px;
    text-decoration: none;

    &:active {
      transition: all .8s ease;
      color: rgba(255, 222, 173, 1);
      border: 1px solid rgba(255, 222, 173, 1);

    }
  }

  &__container{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1002;
    padding-top:100px;


    h1 {
      color: $white;
      font-family: "Comfortaa";
      font-weight: 100;
      text-align: center;
      font-size: 5em;

      #we {
        text-shadow: 0 0 5px white, 0 0 8px white, 2px 2px 2px rgba(206,89,55,0);
      }
      #re {
        text-shadow: 0 0 5px antiquewhite, 0 0 8px antiquewhite, 2px 2px 2px rgba(206,89,55,0);
      }
      #wolf {
        text-shadow: 0 8px 20px white, 0 8px 50px white, 2px 2px 2px rgba(206,89,55,0);
      }
      #remote {
        font-size: 20px;
        color: antiquewhite;
      }
    }

    @media screen and (max-width: 780px) {
      h1 {
        font-size: 3.1em;
      }
    }

    p {
      color: floralwhite;
      font-size: 18px;
      text-align: center;
      word-spacing: 3px;
      transform: translate(0px, 15px);
    }


    .creator_name {
      font-size: 18px;
      font-weight: bolder;
      color: antiquewhite;
      margin-top: 40px;
    }

    @media screen and (max-width: 780px) {
      p, .creator_name {
        font-size: 13px;
      }
    }

    .button-group {
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      transform: translate(0px, 30px);
    }
    button {
      margin: 20px 20px;
      color: antiquewhite;
      background:black;
      border: 1px solid antiquewhite;
      font-family: "Comfortaa";

      &:hover {
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.2) !important;
        border: 1px solid antiquewhite !important;
      }
      &:focus {
        color: antiquewhite;
        background: black;
        border: 1px solid antiquewhite;
      }
      &:active {
        outline: none !important;
        box-shadow: none !important;
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.4) !important;
        border: 1px solid antiquewhite !important;
      }
    }
    .wolf {
      width: 120px;
      height: auto;
      transform: scaleX(-1);
    }
  }
}


// join game modal
.landing-modal {
  .modal-content {
    background:none !important;
    border: none !important;
  }
  .close {
    display: none;
  }

  .modalHeader {
    background: black;
    border: 1px solid antiquewhite;
    border-radius: 12px 12px 0 0;
    border-bottom: none;
  }
  .modalTitle {
    color: antiquewhite;
    font-family: "Comfortaa";
  }
  .modalBody {
    background: black !important;
    border-radius: 0 0 12px 12px;
    border: 1px solid antiquewhite;
    border-top: none;
    font-family: "Comfortaa";
    .form-control {
      background: antiquewhite;
    }
  }
  .button {
    color: antiquewhite;
    background: black;
    border: 1px solid antiquewhite;
    font-family: "Comfortaa";

    &:hover {
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid antiquewhite !important;
    }
    &:focus {
      color: antiquewhite;
      background: black;
      border: 1px solid antiquewhite;
    }
    &:active {
      outline: none !important;
      box-shadow: none !important;
      color: antiquewhite !important;
      background: rgba(255, 255, 255, 0.4) !important;
      border: 1px solid antiquewhite !important;
    }
  }
}


// Socail Media Sharing
.social-button {
  transition: all 0.3s;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  background-color: rgba(18,18,18,1);
  // background-color:black;
  color: rgba(255, 222, 173, 1);
  font-size: 24px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.22);
  cursor: pointer;
  transition-delay: 0.3s;
  z-index: 2000;
  border: 2px solid rgba(255, 222, 173, .5);
}
.social-button-open {
  transition-delay: 0s;
  bottom: 85px;
  background-color: black;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(255, 222, 173, .5);
  color: rgba(255, 222, 173, 1);
}
.social-button .social-point{
  transition: all 0.3s !important; 
  opacity: 0;
  width: 0px;
  height: 0px;
  text-align: center;
  line-height: 50px !important;
  border-radius: 50% !important;
  color: #fff !important;
  font-size: 24px !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.22) !important;
  cursor: pointer;
  visibility: hidden;
  border: 1px solid rgba(255, 222, 173, 1) !important;
}
.social-button .social-point:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.28), 0 4px 15px 0 rgba(0, 0, 0, 0.25) !important;
}
.social-button .social-point:nth-child(2) {
  transition-delay: 0s !important;
  position: absolute !important;
  right: 50px !important;
  bottom: 50px !important;
  background-color: #25D366 !important;
}
.social-button .social-point:nth-child(3) {
  transition-delay: 0s !important;
  position: absolute !important;
  right: 50px !important;
  bottom: 50px !important;
  background-color: #FF5700 !important;
}
.social-button .social-point:nth-child(4) {
  transition-delay: 0s !important;
  position: absolute !important;
  right: 50px !important;
  bottom: 50px !important;
  background-color: #1da1f2 !important;
}
.social-button .social-point:nth-child(5) {
  transition-delay: 0.6s;
  position: absolute;
  right: 50px;
  bottom: 50px;
  background-color:#0088cc !important;
}
.social-button .social-point:nth-child(6) {
  transition-delay: 0s !important;
  position: absolute;
  right: 50px;
  bottom: 50px;
  background-color: #3b5998 !important;
}
.social-button-open .social-point-open{
  opacity: 1 !important;
  width: 50px;
  height: 50px;
  visibility: visible;
}
.social-button-open .social-point-open:nth-child(2) {
  right: 5px !important;
  bottom: 80px !important;
}
.social-button-open .social-point-open:nth-child(2):hover {
  transition-delay: 0s !important;
  background-color: #1EA551 !important;
}
.social-button-open .social-point-open:nth-child(2):focus {
  outline:0 !important;
}
.social-button-open .social-point-open:nth-child(3) {
  right: 65px !important;
  bottom: 65px !important;
}
.social-button-open .social-point-open:nth-child(3):hover {
  transition-delay: 0s !important;
  background-color: #B14237 !important;
}
.social-button-open .social-point-open:nth-child(3):focus {
  outline:0 !important;
}
.social-button-open .social-point-open:nth-child(4) {
  right: 80px !important;
  bottom: 5px !important;
}
.social-button-open .social-point-open:nth-child(4):hover {
  transition-delay: 0s !important;
  background-color: #1980BF !important;
}
.social-button-open .social-point-open:nth-child(4):focus {
  outline:0 !important;
}
.social-button-open .social-point-open:nth-child(5) {
  right: 65px;
  bottom: -65px;
}
.social-button-open .social-point-open:nth-child(5):hover {
  transition-delay: 0s;
  background-color: rgb(0, 100, 170) !important;
}
.social-button-open .social-point-open:nth-child(5):focus {
  outline:0 !important;
}
.social-button-open .social-point-open:nth-child(6) {
  right: 5px !important; 
  bottom: -80px !important; 
}
.social-button-open .social-point-open:nth-child(6):hover {
  transition-delay: 0s !important;
  background-color: #2F4575 !important;
  
}
.social-button-open .social-point-open:nth-child(6):focus {
  outline:0 !important;
}

.buymeacoffee-button {
  position: fixed;
  left: 25px;
  bottom: 25px;
  z-index: 2000;
}
.bmc-button img {
  // height: 34px !important;
  width: 35px !important;
  margin-bottom: 1px !important;
  box-shadow: none !important;
  border: none !important;
  vertical-align: middle !important;
}
.bmc-button {
  padding: 7px 15px 7px 10px !important;
  line-height: 35px !important;
  height: 51px !important;
  text-decoration: none !important;
  display: inline-flex !important;
  color: rgba(255, 222, 173, 1) !important;
  // background-color: antiquewhite !important;
  background-color: rgba(20,20,20,1) !important;
  border-radius: 5px !important;
  // border: 1px solid transparent !important;
  padding: 7px 10px 7px 10px !important;
  font-size: 22px !important;
  letter-spacing: 0.6px !important;
  box-shadow: 0px 0px 1px 2px rgba(255, 222, 173, .5) !important;
  -webkit-box-shadow: 0px 0px 1px 2px rgba(255, 222, 173, .5) !important;
  margin: 0 auto !important;
  font-family: 'Cookie', cursive !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  span {
    transition: all .3s;
    max-width: 0;
    overflow: hidden;
  }
}
.bmc-button:hover,
.bmc-button:active,
.bmc-button:focus {
  -webkit-box-shadow: 0px 1px 2px 2px  rgba(255, 222, 173, .5) !important;
  text-decoration: none !important;
  box-shadow: 0px 1px 2px 2px rgba(255, 222, 173, .5) !important;
  opacity: 0.85 !important;
  color: rgba(255, 222, 173, 1) !important;
  > span {
    max-width: 1000px;
    transition: all 0.5s;
    overflow: unset;
    padding-right: 5px;
  }

}



.clouds{
  background:transparent url("../../images/clouds-color.png") repeat top center;
  // position: fixed; // TODO: Change it back to fixed
  position: absolute;
  bottom: 0;
  z-index: 900;
  width: 100%;
  height: 100vh;
  // top: -30vh;
  // transform: translate(0, 200px);
  // transform: translate(0, 15vh);

  animation:move-clouds-back 200s linear infinite;
}


@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}


.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: rgba(255, 222, 173, .8);
  font-family: "Comfortaa";
  font-size: 15px;
  letter-spacing: 2px;
  margin-top: 10vh;

  .terms {
    font-size: 8px;
    color: rgba(255, 222, 173, .5);
    margin-bottom: 4px;

    span {
      padding: 5px;
    } 
  }
}

.email {
  display:inline-block;
  unicode-bidi:bidi-override;
  direction:rtl;

  &:hover {
    color: rgba(255, 222, 173, 1);
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .footer  {
    letter-spacing: 1.2px;

    .email {
      font-size: 11px;
    }
    .terms {
      font-size: 8px;
    }
  }
}

