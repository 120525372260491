@import "~bootstrap/scss/bootstrap";

$bullet-yellow: #FFEA7C;

.wrapper {
  background: url("../../images/Black-Wolf-Wallpaper.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: $white;
  font-family: "Comfortaa";

  h1 {
    color: $white;
    font-family: "Comfortaa";
    font-weight: 100;
    text-align: center;
    font-size: 5em;
    padding-top: 30px;
    margin-bottom: 60px;

    #we {
      text-shadow: 0 0 5px white, 0 0 8px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #re {
      text-shadow: 0 0 5px antiquewhite, 0 0 8px antiquewhite, 2px 2px 2px rgba(206,89,55,0);
    }
    #wolf {
      text-shadow: 0 8px 20px white, 0 8px 50px white, 2px 2px 2px rgba(206,89,55,0);
    }
    #remote {
      font-size: 20px;
      color: antiquewhite;
    }
  }

  @media screen and (max-width: 780px) {
    h1 {
      font-size: 3.1em;
    }
  }

  .game {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      background-color: rgba(0,0,0,.7);
      border-radius: 5px;
      padding: 20px;
      position: relative;

      span {
        color:antiquewhite;
      }

      .text-right {
        button {
          color: antiquewhite;
          background: black !important;
          border: 1px solid antiquewhite;
          font-family: "Comfortaa";

          &:focus {
            color: antiquewhite;
            background: black;
            border: 1px solid antiquewhite;
          }
        }

      }

      .pregame-message {

        p {
          color: lawngreen;
          font-size: 12px;
          text-align: center;
          margin-bottom: 2px;
        }

      }

      .room-id {
        position: absolute;
        top: -40px;
        left: 0;
        color: darkgrey;
        margin-left: 30px;
        font-size: 13px;
      }

      .text-right {
        transform: translate(0px, -30px);
      }

      .wake-up {
        text-align: center;

        .message-board {
          background: rgba(0,0,0,.4);
          padding-top: 1px;
          padding-bottom: 1px;
          border-radius: 10px;
          min-height: 80px;
          border: 1px solid dimgrey;
          color: darkgrey;

          p {
            margin-top: 5px;
            margin-bottom: 0px;
            font-size: 15px;
            color: $bullet-yellow;
          }

          ul {
            list-style-type: none;
            padding: 0px;
            font-size: 12px;
            max-width: 300px;
            margin-top: 5px;
            margin-bottom: 10px;
            text-align: left;
            padding-left: 16px;
          }
        }

        button {
          color: antiquewhite;
          background:black;
          border: 1px solid antiquewhite;
          font-family: "Comfortaa";
          margin-top: 25px;
          // padding: 9px;

          &:hover {
            color: antiquewhite !important;
            background: rgba(255, 255, 255, 0.2) !important;
            border: 1px solid antiquewhite !important;
          }
          &:focus {
            color: antiquewhite;
            background: black;
            border: 1px solid antiquewhite;
          }
          &:active {
            outline: none !important;
            box-shadow: none !important;
            color: antiquewhite !important;
            background: rgba(255, 255, 255, 0.4) !important;
            border: 1px solid antiquewhite !important;
          }
        }

        .isModified {
          animation: glowing 1500ms infinite;
        }

        @keyframes glowing {
          0% { background-color: rgba(255, 255, 255, 0.1); box-shadow: 0 0 3px rgba(255, 255, 255, 0.1); }
          50% { background-color: rgba(255, 255, 255, 0.4); box-shadow: 0 0 40px rgba(255, 255, 255, 0.4); }
          100% { background-color: rgba(255, 255, 255, 0.1); box-shadow: 0 0 3px rgba(255, 255, 255, 0.1); }
        }

        .end-game-btn {
          background: rgba(255,0,0,0.5);
          &:hover {
            color: antiquewhite !important;
            background:  firebrick !important;

          }
          &:focus {
            color: antiquewhite;
            background: rgba(255,0,0,0.5);
          }
          &:active {
            background:red !important;
          }
        }
      }

      .players {
        width: 310px;

        .player {
          background-color: $white;
          border-radius: 5px;
          color: $black;
          padding: 5px;
          margin-bottom: 5px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

          &__status {
            margin-left: auto;
          }

          &__name {
            font-size: 1.5em;
            text-transform: uppercase;
            font-weight: bold;
            color: #867E6E;
          }
          &__role {
            font-size: 1em;
            color: $gray-300;
          }

          &.dead {
            background: rgba(187, 187, 187, 0.3);
          }

        }
      }

    }
  }

}

// sticky note

#popover-basic {
  border: none;
}
.popover-header {
  background: rgba(0,0,0,1) !important;
  color: antiquewhite !important;
  font-family: "Comfortaa";
  border: 1px solid antiquewhite !important;
  border-bottom: none !important;
  padding: 0.6rem 1rem !important;
  font-size: 15px !important;
}
.popover-body {
  background: rgba(0,0,0,.9) !important;
  font-family: "Comfortaa";
  border: 1px solid antiquewhite !important;
  padding-bottom: 0px !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  tbody {
    color: antiquewhite !important;
    td {
      padding: 0.4rem;
    }
  }
}

// $bullet-yellow: #FFEA7C;

.timeline {
  width: 310px;
  font-family: 'Comfortaa';
  font-size: 12px;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 6px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.2);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::after {
    // Clearfix to adjust empty height
    content: " ";
    display: block;
    height: 0;
    clear: both;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    float: left;
    // display: inline-block;
    padding: 10px 5px;
    border-top: 2px solid $white;
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 3px;
      transition: width 1s;
    }

    &::after {
      // Dot
      z-index: 1000;
      content: "";
      height: 12px;
      width: 12px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: calc(50% - 6px);
      top: -8px;
      border: 3px solid $black;
    }
  }

  .completed {
    color: $bullet-yellow;

    &::after {
      background-color: $bullet-yellow;
    }

    &::before {
      content: "";
      z-index: 10;
      width: 100%;
      height: 3px;
      background-color: $bullet-yellow;
      position: absolute;
      top: -3px;
      left: 0;
    }
  }

  .in-progress {
    color: $bullet-yellow;

    &::after {
      background-color: $bullet-yellow;
    }

    &::before {
      content: "";
      z-index: 10;
      width: 50%;
      height: 3px;
      background-color: $bullet-yellow;
      position: absolute;
      top: -3px;
      left: 0;
      transition-delay: 1s;
    }
  }
}
