@import "~bootstrap/scss/bootstrap";

#start-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  height: 90%;
  margin: 0 auto 5%;
  padding-top: 50px;
}

.start-item {
  width: 32%;
  text-align: center;
  background: #ffffff;
  margin-bottom: 2%;
  padding: 0 0 7%;
  font-size: 1em;
  border-radius: 10px;
  // background: url("../../images/pngtree-cartoon-hand-drawn-moonlight-wolf-howling-hunting-background-material-image_158632.jpg")no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.start-item:nth-child(4),
.start-item:nth-child(5),
.start-item:nth-child(6) {
  margin-bottom: 0;
}

.start-item p {
  margin-top: 20px;
  font-size: 2vw;


}

.start-item img {
  width: 50%;
  border-radius: 10px;
}

.start-button-component {


  button {
    font-size: 0.8em;
    width: 25%;
    height: auto;
    border-radius: 10px;
    background: antiquewhite;
    font-family: "arial";
  }

  span{
    margin: 0;
    display: inline-block;
    margin-top: 30px;
    color: gold;
    font-size: 28px;
    // height: 32px;
    background: radial-gradient(dimgrey, transparent);
    width: 60px;
    font-family: "Caveat";
    letter-spacing: 4px;
    // transform: translate(0px, 0px);
    // padding-top: 3px;
  }

}

.start-button-component button:focus{
  outline:0;
}
