.blurred-bg {
  background-image: url("../../images/default-background.jpg");
  filter: blur(8px);
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.center-text {
  background: rgba(255,255,255,0.06);
  color: white;
  font-weight: bold;
  font-family: "Comfortaa";
  border: 1px solid crimson;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 300px;
  padding: 20px;
  text-align: center;

  h3 {
    color: crimson;
    margin-top: 20px;
    font-size: 25px;
  }

  p {
    font-size: 22px;
    margin-top: 20px;
    color: antiquewhite;
    &:hover {
      color: burlywood;
      cursor: pointer;
    }
  }
}