.selected {
  border: 5px solid limegreen !important;
}

.status-changed {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.you {
  position: absolute;
  font-size: 10px;
  color: #867E6E;
  margin-left: 2px;
}