
.end-modal {
  
  .modal-content {
    background:black !important;
    border: none !important;
  }
  .close {
    display: none;
  }

  .modalHeader {
    border: 1px solid antiquewhite;
    background:black;
    border-radius: 15px 15px 0 0 !important;
    border-bottom: none !important;

    .modalTitle {
      color: antiquewhite;
    }
  }

  .modalBody {
    background:black;
    color:white;
    // border: 1px solid antiquewhite;
    border-bottom: none;
    border-top: none;

    .engGameMessage {
      color: antiquewhite;
    }

    .varRow {
      margin-bottom: 3px;
      align-items: center;
      text-transform: capitalize;
      height: 40px;
      color: antiquewhite;
    }

    .playerRow {
      margin-bottom: 3px;
      align-items: center;
      text-transform: capitalize;
      height: 40px;
      border-radius: 5px;
      background: rgba(255,255,255,0.06);
    }

  }

  .modalFooter {
    background: black;
    border: 1px solid antiquewhite;
    border-radius: 0 0 15px 15px !important;
    border-top: none !important;

    .button {
      color: antiquewhite;
      background: black;
      border: 1px solid antiquewhite;

      &:hover {
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.2) !important;
        border: 1px solid antiquewhite !important;
      }
      &:focus {
        outline: none !important;
        box-shadow: none !important;
        color: antiquewhite !important;
        background: rgba(255, 255, 255, 0.2) !important;
        border: 1px solid antiquewhite !important;
      }
    }
  }

}



@media screen and (max-width: 780px) {
  .modal-body {
    font-size: 12px;
  }
}
@media screen and (min-width: 780px) {
  .modal-body {
    font-size: 15px;
  }
}

