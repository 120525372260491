@import "../Terms/terms.scss";
.demo {
  //General
  //body {
  //  margin: 0;
  //  background-color: #1d1d1e;
  //  font-family: "Roboto", sans-serif;
  //}
  //

  &-container {
   @extend .terms-container;
  }

  * {
    outline: none;
  }

  //Colors
  $white-20: rgba(255, 255, 255, 0.2);
  $white-60: rgba(255, 255, 255, 0.6);
  $white: rgba(255, 255, 255, 1);
  $main-color: #b38c52;

  //Timeline
  .timeline-carousel {
    padding: 86px 6.9444% 90px 6.9444%;
    position: relative;
    overflow: hidden;
    &:after,
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      height: 100%;
      width: 6.9444%;
      //background-color: #1d1d1e;
      z-index: 3;
      width: 6.9444%;
    }
    &:after {
      left: 0;
    }
    &:before {
      right: 0;
      opacity: 0;
    }
    .slick-list {
      overflow: visible;
    }
    .slick-dots {
      bottom: -73px;
    }
    h1 {
      color: $white;
      font-size: 46px;
      line-height: 50pd;
      margin-bottom: 40px;
      font-weight: 900;
    }
    &__image {
      padding-right: 30px;
    }
    &__item {
      cursor: pointer;
      .media-wrapper {
        opacity: 0.4;
        padding-bottom: 71.4%;
        -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);

        &--overlay {
          background: url('https://via.placeholder.com/530x316') center center;
          // background: url('../../images/tutorial/host-game-setting.gif') center center;
          background-size:cover;
        }
      }
      &:last-child {
        .timeline-carousel__item-inner {
          &:after {
            width: calc(100% - 30px);
          }
        }
      }
    }
    &__item-inner {
      position: relative;
      padding-top: 45px;
      &:after {
        position: absolute;
        width: 100%;
        top: 45px;
        left: 0;
        content: "";
        border-bottom: 1px solid $white-20;
      }
      .year {
        font-size: 36px;
        line-height: 36px;
        color: $white;
        display: table;
        letter-spacing: -1px;
        padding-right: 10px;
        //background-color: #1d1d1e;
        z-index: 1;
        position: relative;
        margin: -15px 0 20px;
        font-weight: 900;
        &:after {
          content: "";
          position: absolute;
          display: block;
          left: -10px;
          top: 0;
          height: 100%;
          width: 10px;
          //background-color: #1d1d1e;
          z-index: 3;
        }
      }
      .month {
        font-size: 12px;
        text-transform: uppercase;
        color: $main-color;
        display: block;
        margin-bottom: 10px;
        font-weight: 900;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: $white;
        width: 60%;
        font-weight: 400;
        margin-bottom: 15px;
      }

      .read-more {
        font-size: 12px;
        color: $main-color;
        display: table;
        margin-bottom: 10px;
        font-weight: 900;
        text-decoration: none;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -1px;
          width: 0;
          border-bottom: 2px solid $main-color;
          -webkit-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
          -o-transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        }
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
      .pointer {
        height: 29px;
        position: relative;
        z-index: 1;
        margin: -4px 0 16px;
        &:after,
        &:before {
          position: absolute;
          content: "";
        }
        &:after {
          width: 9px;
          height: 9px;
          border-radius: 100%;
          top: 0;
          left: 0;
          background-color: $main-color;
        }
        &:before {
          width: 1px;
          height: 100%;
          top: 0;
          left: 4px;
          background-color: $main-color;
        }
      }
    }

    .slick-active {
      .media-wrapper {
        opacity: 1 !important;
      }
    }
  }

  .slick-dots {
    bottom: 60px;
    list-style: none;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 2;
    li {
      cursor: pointer;
      display: inline-block;
      margin: 0 6px;
      position: relative;
      width: 10px;
      height: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.slick-active {
        button {
          background: $main-color;
          border-color: $main-color;
        }
      }
      button {
        display: block;
        font-size: 0;
        width: 10px;
        height: 10px;
        padding: 0;
        background-color: $white-60;
        border-color: $white-60;
        cursor: pointer;
        -webkit-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        -o-transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);

        transition: all 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53);
        &:hover {
          background: $main-color;
          border-color: $main-color;
        }
      }
    }
  }


  .link{
    position:absolute;
    left:0;
    bottom:0;
    padding:20px;
    z-index:9999;
    a{
      display:flex;
      align-items:center;
      text-decoration:none;
      color:#fff;
    }
    .fa{
      font-size:28px;
      margin-right:8px;
      color:#fff;
    }
  }
}
