@import "~bootstrap/scss/bootstrap";

.role-card {
  width: 110px;
  height: 150px;
  min-width: 110px;
  max-width: 110px;
  background: $white;
  border-radius: 5px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  border: 3px solid lightgrey;

  transform: scale(1); /* you need a scale here to allow it to transition in both directions */
  transition: 0.15s all ease;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);

  &:hover {
    transform: scale(1.05);
    border: 2px solid #ddd;
  }

  .role-name {
    color: $black;
    padding-top: 10px;
    font-family: Roboto;
  }
}

.role-card-modal {
  .modal-header {
    border: 0;
  }
  .modal-body {
    text-align: center;
    .role-name {
      font-size: 2em;
      font-family: Roboto;
      padding-top: 20px;
    }
    .role-details {

    }
  }
}
