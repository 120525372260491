.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow: 0px 0px 5px antiquewhite, 0px 0px 3px antiquewhite, 0px 0px 3px antiquewhite;
  p {
    color: gainsboro;
    margin: 0;
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    letter-spacing: 5px;  
  }
  @keyframes blinker { to { opacity: 0; } }
}